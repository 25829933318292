import React from 'react'
import { Route, Routes } from 'react-router'
import Errors from './errors'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/error" element={<Errors />} />
    </Routes>
  )
}

export default AllRoutes
