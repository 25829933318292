import React from 'react'
import styled from '@emotion/styled'
import ErrorImage from '../../../static/error.png'

const Center = styled.div({
  placeItems: 'center',
  display: 'grid',
  height: '100vh'
})

const Wrapper = styled.div({
  placeItems: 'center',
  display: 'grid'
})

const Errors = () => {
  return (
    <Center>
      <Wrapper>
        <img src={ErrorImage} />
        <h1>Oops! Something went wrong.</h1>
      </Wrapper>
    </Center>
  )
}

export default Errors
